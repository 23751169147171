/**
 * @file Main
 * @author Rhys Lloyd
 */
/* eslint-disable unicorn/prefer-top-level-await */

// DevTools is a side effect import, imports nothing for production.
// eslint-disable-next-line import/no-unassigned-import
import './main-devtools';

const windowObject = window as unknown as {
    isStorybook: boolean | undefined
};

// Globals
window.AppGlobals = window.AppGlobals ?? {};
window.CdnFallbacks = window.CdnFallbacks ?? [];
window.AppHooks = window.AppHooks ?? {
    initialise: [],
    beforeMount: [],
    afterMount: [],
    ready: [],
};

// Run
if (!window.App) {
    const ensureCdnPromises = window.CdnFallbacks.map(async (fallback) =>
        import(/* @vite-ignore */ fallback.src)
            .then((m) => {
                // Verify global
                const isExpectingGlobal = Boolean(fallback.global);
                // @ts-expect-error Window is typed with any :-/
                const hasFoundGlobal = Boolean(window[fallback.global]);

                if (isExpectingGlobal && !hasFoundGlobal) {
                    throw new Error(`Unable to find CDN fallback global`);
                }

                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return m;
            })
            .catch((error) => {
                console.error(`Failed to import CDN fallback from '${fallback.src}'`, error);
            })
    );

    Promise.all(ensureCdnPromises)
        .then(async () => import('./app'))
        .then(async ({ App }) => !windowObject.isStorybook && new App().autoStart())
        .catch(console.error);
}
